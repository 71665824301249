import produce from "immer";

const INITIAL_STATE = { value: {}, apiFilter: {} };
export default function filter(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case "@filter/UPDATE_FILTER": {
                draft.value = action.payload.filter;
                break;
            }
            case "@filter/ADD_FILTER": {
                draft.apiFilter = action.payload.apiFilter;
                break;
            }
            case "@filter/REMOVE_FILTER": {
                draft.value = {};
                break;
            }
            default:
        }
    });
}
