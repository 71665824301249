import produce from "immer";

const INITIAL_STATE = {
    startsAt: null,
    endsAt: null,
};

export default function period(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        const { startsAt, endsAt } = action.payload || {};
        switch (action.type) {
            case "@period/UPDATE_PERIOD":
                draft.startsAt = startsAt;
                draft.endsAt = endsAt;
                break;
            case "@period/REMOVE_PERIOD":
                draft.startsAt = null;
                draft.endsAt = null;
                break;
            default:
        }
    });
}
