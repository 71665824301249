import { takeLatest, call, put, all } from "redux-saga/effects";
import isBrowser from "../../../helpers/isBrowser";

import api from "../../../services/api";
import { addFilter } from "./actions";

export function* getFilter() {
    if (!isBrowser()) {
        return;
    }
    try {
        const token = window.localStorage.getItem("session-token");

        if (token) {
            const res = yield call(api.get, "/filter/list", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
            yield put(addFilter(res.data));
        }
    } catch (error) {
        console.log(error);
    }
}

export default all([takeLatest("persist/REHYDRATE", getFilter), takeLatest("@filter/REMOVE_FILTER", getFilter)]);
