import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "../src/config/ReactotronConfig";
import { store, persistor } from "../src/store";

export const wrapRootElement = ({ element }) => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>{element}</PersistGate>
    </Provider>
);
