import axios from "axios";
import { toast } from "react-toastify";
import { withPrefix, navigate } from "gatsby";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.response.use(
    response => {
        let token = window.localStorage.getItem("session-token") ?? null;
        if (token) {
            let loggedAt = new Date(JSON.parse(atob(token.split(".")[1]))?.iat * 1000);
            if (loggedAt < new Date(1669127572000)) {
                window.localStorage.clear();
            }
        }

        return response;
    },
    error => {
        if (error.response?.status === 401) {
            if (window.location.pathname !== "/") {
                window.localStorage.setItem("redirect_to", window.location.pathname);
            }

            window.localStorage.removeItem("session-token");
            if (window.location.pathname === withPrefix("/")) {
                navigate("/");
            } else {
                window.location.href = "/";
            }
        }

        if (error.response?.status === 500) {
            toast.error("Algo deu errado, tente novamente mais tarde!");
        }

        if (error.response) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                error.response.data.errors.map(error => {
                    return toast.error(error.message);
                });
            } else if (error.response.data.message) {
                if (
                    error.response.data.message === "Invalid JWT Token" ||
                    error.response.data.message === "Expired JWT Token"
                ) {
                    toast.error("Seu acesso expirou, faça sua autenticação novamente.");
                    window.localStorage.clear();
                } else if (error.response.data.message === "Invalid credentials.") {
                    toast.error("Combinação de e-mail e senha inválida.");
                } else {
                    toast.error(error.response.data.message);
                }
            }
        } else {
            toast.error("Algo deu errado, tente novamente mais tarde!");
        }

        return Promise.reject(error);
    }
);

export default api;
